import React, { useState, useContext, useEffect } from "react";
import { NavLink, useNavigate, useLocation, Navigate } from "react-router-dom";
import axios from "axios";
import { Avatar, Menu, MenuItem } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import HomeIcon from "@mui/icons-material/Home";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'; // Down arrow icon for expanding
import ExpandLessIcon from '@mui/icons-material/ExpandLess'; // Up arrow icon for collapsing

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import { AuthLoginInfo } from "./../AuthComponents/AuthLogin";
import "./Styles/sidebar.css";
import {base} from "../config"
const API_URL = base(window.env.AP);

const logout = () => {
  axios
    .get(`${API_URL}/logout`, { withCredentials: true })
    .then((res) => {
      if (res.data === "success") {
        window.location.href = "/Login";
      }
    });
};

const NavbarSection = ({ ctx, toggleSidebar }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const isDashboard = location.pathname === "/Home";
  const [anchorEl, setAnchorEl] = useState(null);
  const [breadcrumbs, setBreadcrumbs] = useState([]);

  useEffect(() => {
    setBreadcrumbs((prevBreadcrumbs) => {
      const newBreadcrumbs = [...prevBreadcrumbs];
      if (!newBreadcrumbs.some((breadcrumb) => breadcrumb.path === location.pathname)) {
        newBreadcrumbs.push({
          path: location.pathname,
          name: location.pathname.split('/').pop() || 'Home',
        });
      }
      return newBreadcrumbs;
    });
  }, [location]);

  const handleAvatarClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleAvatarClose = () => {
    setAnchorEl(null);
  };

  const handleBreadcrumbClick = (path) => {
    navigate(path);
  };

  return (
    <nav className="mnb">
      <div className="navbar-header">
        <div
          className="left-icons-and-breadcrumb"
          style={{ display: "flex", flexDirection: "column", padding: "10px" }}
        >
          <div className="nav-icons-left">
            {!isDashboard && (
              <ArrowBackIcon
                onClick={() => navigate(-1)}
                style={{ cursor: "pointer", marginRight: "10px", fontSize: "1.2rem" }}
              />
            )}
            <span style={{ fontWeight: "bolder" }}>
              @ DoingERP.com
            </span>
          </div>
          <div className="breadcrumb-container">
            <Breadcrumbs aria-label="breadcrumb">
              {breadcrumbs.map((breadcrumb, index) => (
                <Link
                  key={breadcrumb.path}
                  color={index < breadcrumbs.length - 1 ? "inherit" : "textPrimary"}
                  onClick={() => handleBreadcrumbClick(breadcrumb.path)}
                  style={{
                    cursor: "pointer",
                    textDecoration: index < breadcrumbs.length - 1 ? "underline" : "none",
                    fontWeight: index < breadcrumbs.length - 1 ? "normal" : "bold",
                    marginRight: "5px",
                    fontSize: "1.2rem",
                  }}
                >
                  {breadcrumb.name[0].toUpperCase() + breadcrumb.name.slice(1)}
                </Link>
              ))}
            </Breadcrumbs>
          </div>
        </div>

        <div className="nav-icons-right" style={{ marginTop: "10px" }}>
          <div style={{ marginRight: "10px", marginTop: "10px" }}>
            <HomeIcon
              onClick={() => navigate("/")}
              style={{ cursor: "pointer", fontSize: "2rem" }}
            />
          </div>
          <Avatar
            onClick={handleAvatarClick}
            style={{ cursor: "pointer", backgroundColor: "#3f51b5", fontSize: "1.2rem", width: "50px", height: "50px" }}
          >
            {ctx.username[0].toUpperCase()}
          </Avatar>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleAvatarClose}
          >
            <MenuItem sx={{ fontSize: "1.2rem" }} onClick={logout}>Logout</MenuItem>
          </Menu>
        </div>
      </div>
    </nav>
  );
};
// const SidebarSection = ({ ctx, sidebarClass, toggleSidebar }) => {
//   const [sidebarData, setSidebarData] = useState([]);
//   const [expandedCategories, setExpandedCategories] = useState({});

//   useEffect(() => {
//     if (ctx?.role_id) {
//       axios.get(`${API_URL}/menulevel?role=${ctx.role_id}`, { withCredentials: true })
//         .then(res => {
//           setSidebarData(res.data);
//         });
//     }
//   }, [ctx?.role_id]);

// useEffect(() => {
//     // Reset the expanded categories when the route changes
//     setExpandedCategories({});
//   }, [location.pathname]); // Trigger when pathname changes
//   // Group sidebarData by category
//   const groupedData = sidebarData.reduce((acc, item) => {
//     if (item?.role !== undefined && item?.role !== ctx?.role_id) {
//       return acc;
//     }

//     if (item?.Category) {
//       if (!acc[item.Category]) {
//         acc[item.Category] = [];
//       }
//       acc[item.Category].push(item);
//     }

//     return acc;
//   }, {});

//   const handleCategoryToggle = (category) => {
//     if (sidebarClass === "msb") {
//       setExpandedCategories(prevState => ({
//         ...prevState,
//         [category]: !prevState[category]
//       }));
//     }
//   };

//   let sidebarHeaderStyle = {
//     backgroundColor: sidebarClass === "msb" ? "transparent" : "gray",
//     display: "flex",
//     justifyContent: "center",
//     alignItems: "center",
//     position: "sticky",
//     top: 0,
//     zIndex: 1,
//     height: sidebarClass === "msb" ? "" : "50px",
//     marginTop: sidebarClass === "msb" ? "10%" : "0",
//   };

//   const lipadding = {
//     paddingBottom: sidebarClass === "msb" ? "7%" : "25px",
//     paddingLeft: sidebarClass === "msb" ? "0%" : "20px"
//   };

//   return (
//     <div className={sidebarClass} id="msb">
//       <nav role="navigation">
//         <div className="sidebar-header" style={sidebarHeaderStyle}>
//           {sidebarClass === "msb" ? (
//             <div>
//               <CloseIcon
//                 onClick={toggleSidebar}
//                 style={{ cursor: "pointer", fontSize: "1.8rem" }}
//               />
//               <h3 className="brand" style={{ fontWeight: "bolder" }}>@ DoingERP.com</h3>
//             </div>
//           ) : (
//             <MenuIcon
//               onClick={toggleSidebar}
//               className="sidebar-icon"
//             />
//           )}
//         </div>
//         <div className="side-menu-container">
//           <ul className="nav navbar-nav" style={{ padding: "0" }}>
//             {Object.keys(groupedData).map((category, index) => (
//               <React.Fragment key={index}>
//                 <li>
//                   <button
//                     className="category-header"
//                     onClick={() => handleCategoryToggle(category)}
//                     style={{
//                       background: 'none',
//                       border: 'none',
//                       cursor: 'pointer',
//                       fontSize: '1.2rem',
//                       padding: '10px',
//                       textAlign: 'left',
//                       width: '100%',
//                       display: 'flex',
//                       alignItems: 'center',
//                       justifyContent: 'space-between'
//                     }}
//                   >
//                     <div style={{ display: 'flex', alignItems: 'center' }}>
//                       {/* Icon for the category */}
//                       <i className={`fa ${groupedData[category][0]?.icon.toLowerCase()}`} style={{ fontSize: '1.5rem', marginRight: '10px' }}></i>
//                       {/* Category name */}
//                       {sidebarClass === "msb" && <span>{category}</span>}
//                     </div>
//                     {sidebarClass === "msb" && (
//                       expandedCategories[category] ? (
//                         <ExpandLessIcon style={{ fontSize: '1.2rem' }} />
//                       ) : (
//                         <ExpandMoreIcon style={{ fontSize: '1.2rem' }} />
//                       )
//                     )}
//                   </button>
//                   {expandedCategories[category] && (
//                     <ul className="category-items" style={{ padding: '0', listStyleType: 'none' }}>
//                       {groupedData[category].map((val, key) => (
//                         <li key={key} style={lipadding}>
//                           <NavLink
//                             to={val.link}
//                             className={({ isActive }) =>
//                               isActive ? "sidebar-active-link" : "sidebar-link"
//                             }
//                             onClick={toggleSidebar}
//                           >
//                             <i className={`fa ${val.icon.toLowerCase()}`} style={{ fontSize: "1.8rem" }}></i>
//                             {sidebarClass === "msb" && val.title}
//                           </NavLink>
//                         </li>
//                       ))}
//                     </ul>
//                   )}
//                 </li>
//               </React.Fragment>
//             ))}
//           </ul>
//         </div>
//       </nav>
//     </div>
//   );
// };
const SidebarSection = ({ ctx, sidebarClass, toggleSidebar }) => {
  const [sidebarData, setSidebarData] = useState([]);

  useEffect(() => {
    if (ctx?.role_id) {
      axios.get(`${API_URL}/menulevel?role=${ctx.role_id}`, { withCredentials: true })
        .then(res => {
          setSidebarData(res.data);
        });
    }
  }, [ctx?.role_id]);

  let sidebarHeaderStyle = {
    backgroundColor: sidebarClass === "msb" ? "transparent" : "gray",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "sticky",
    top: 0,
    zIndex: 1,
    height: sidebarClass === "msb" ? "" : "50px",
    marginTop: sidebarClass === "msb" ? "10%" : "0",
  };

  const lipadding = {
    paddingBottom: sidebarClass === "msb" ? "7%" : "25px",
    paddingLeft: sidebarClass === "msb" ? "0%" : "20px"
  };

  return (
    <div className={sidebarClass} id="msb">
      <nav role="navigation">
        <div className="sidebar-header" style={sidebarHeaderStyle}>
          {sidebarClass === "msb" ? (
            <div>
              <CloseIcon
                onClick={toggleSidebar}
                style={{ cursor: "pointer", fontSize: "1.8rem" }}
              />
              <h3 className="brand" style={{ fontWeight: "bolder" }}>@ DoingERP.com</h3>
            </div>
          ) : (
            <MenuIcon
              onClick={toggleSidebar}
              className="sidebar-icon"
            />
          )}
        </div>
        <div className="side-menu-container">
          <ul className="nav navbar-nav" style={{ padding: "0" }}>
            {sidebarData.map((val, key) => {
              if (val?.role !== undefined && val?.role !== ctx?.role_id) {
                return null;
              }
              return (
                <li key={key} style={lipadding}>
                  <NavLink
                    to={val.link}
                    className={({ isActive }) =>
                      isActive ? "sidebar-active-link" : "sidebar-link"
                    }
                    onClick={toggleSidebar}
                  >
                    <i className={`fa ${val.icon.toLowerCase()}`} style={{ fontSize: "1.8rem" }}></i>
               
                    {sidebarClass === "msb" && val.title}
                  </NavLink>
                </li>
              );
            })}
          </ul>
        </div>
      </nav>
    </div>
  );
};

function Sidebar() {
  const ctx = useContext(AuthLoginInfo);

  const [sidebarClass, setSidebarClass] = useState("msb");

  const toggleSidebar = () => {
    setSidebarClass(sidebarClass === "msb" ? "msb-x" : "msb");
  };

  return (
    <div className="SidebarWrapper">
      <SidebarSection
        ctx={ctx}
        sidebarClass={sidebarClass}
        toggleSidebar={toggleSidebar}
      />
      <NavbarSection ctx={ctx} toggleSidebar={toggleSidebar} />
    </div>
  );
}

export default Sidebar;
